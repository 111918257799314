<template>
  <div>
    Display Document Properties Here...
  </div>
</template>

<script>
export default {
  name: 'DocumentProperties',
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
</style>
